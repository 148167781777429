import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { load } from './authLocalStorage';
import { ProviderKinds } from './loginMethods';
import { User } from './types';
import { ApiResult } from 'src/services/types';

export type SessionState = {
  access_token: string;
  expires_at: number;
  refresh_token: string;
  token_type: 'Bearer';
};

export type Session = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  state?: unknown;
  token_type: 'Bearer';
};

export type UserPasswordLoginAttemptData = {
  username: string;
  password: string;
  isRememberMe: boolean;
};

export type TwoFactorAuthLoginAttemptData = {
  codeTwoFactor: string;
};

export type SSOLoginAttemptData = {
  codeSSO: string;
};

export type LoginActionPayload =
  | UserPasswordLoginAttemptData
  | SSOLoginAttemptData
  | TwoFactorAuthLoginAttemptData;

export type ConfirmationDataPayload = {
  access_token: string;
  expires_at: number;
};

export type SSOMethod = {
  kind: ProviderKinds;
  id: string;
};

export type AuthState = {
  session?: SessionState;
  isProcessing: boolean;
  ['twoFactor']?: {
    confirmation?: {
      access_token: string;
      expires_at: number;
    };
    attemptData: UserPasswordLoginAttemptData;
  };
  formErrors: FormError[];
  ssoMethods: SSOMethod[];
  currentUser: ApiResult<User | undefined>;
};

export type FormError = {
  field: string;
  message: string;
};

export const login = createAction<LoginActionPayload>('auth/loginRequest');
export const sessionCreated = createAction<Session>('auth/sessionCreated');
export const persistSession = createAction<boolean>('auth/persistSession');
export const fetchCurrentUser = createAction('profile/fetchCurrentUser');
export const error = createAction<string>('auth/error');
export const fetchLoginMethods = createAction('auth/fetchLoginMethods');
const initialState: AuthState = {
  isProcessing: false,
  session: load(),
  twoFactor: undefined,
  formErrors: [],
  ssoMethods: [],
  currentUser: {
    isFetched: undefined,
    isLoading: false,
    error: null,
    data: undefined
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearFormError(state) {
      state.formErrors = [];
    },
    errorForm(state, action: PayloadAction<FormError>) {
      state.formErrors = [
        ...state.formErrors.filter(({ field }) => action.payload.field !== field),
        action.payload,
      ];
    },
    logout(state) {
      state['twoFactor'] = undefined;
      state.session = undefined;
    },
    resetTwoFactor(state) {
      state['twoFactor'] = undefined;
    },
    setIsProcessing(state, action: PayloadAction<boolean>) {
      state.isProcessing = action.payload;
    },
    setSSOMethods(state, action: PayloadAction<SSOMethod[]>) {
      state.ssoMethods = action.payload;
    },
    setSession(state, action: PayloadAction<SessionState>) {
      state.session = action.payload;
      state['twoFactor'] = undefined;
    },
    setTwoFactorAttemptData(state, action: PayloadAction<UserPasswordLoginAttemptData>) {
      state.session = undefined;
      state['twoFactor'] = {
        ...(state['twoFactor'] || {}),
        attemptData: { ...action.payload },
      };
    },
    setTwoFactorConfirmationData(state, action: PayloadAction<ConfirmationDataPayload>) {
      const confirmation = {
        access_token: action.payload.access_token,
        expires_at: action.payload.expires_at,
      };
      if (state['twoFactor']) {
        state['twoFactor'] = { ...state['twoFactor'], confirmation };
      }
    },
    currentUserFetching(state) {
      state.currentUser.isLoading = true;
      state.currentUser.isFetched = false;
      state.currentUser.error = null;
      state.currentUser.data = undefined;
    },

    currentUserFetchError(state, action: PayloadAction<string>) {
      state.currentUser = {
        isLoading: false,
        isFetched: true,
        error: action.payload,
        data: undefined,
      };
    },
    currentUserFetched(state, action: PayloadAction<User>) {
      state.currentUser = {
        isLoading: false,
        error: null,
        data: action.payload,
        isFetched: true,
      };
    },
  },
});

export const {
  clearFormError,
  errorForm,
  logout,
  resetTwoFactor,
  setIsProcessing,
  setSession,
  setSSOMethods,
  setTwoFactorAttemptData,
  setTwoFactorConfirmationData,
  currentUserFetching,
  currentUserFetchError,
  currentUserFetched
} = authSlice.actions;
export default authSlice;
