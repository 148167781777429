import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store';
import Button from '../components/Button';
import Href from '../components/Href';
import InputCode from '../components/InputCode';
import LogoSubtitle from '../components/LogoSubtitle';
import LoginWrapper from './LoginWrapper';
import { isProcessing, selectFormError } from './authSelectors';
import { clearFormError, login, resetTwoFactor } from './authSlice';
import { twoFactorPanelSchema } from './schemas';
import { DigitFields, DigitValues } from './types';

const selectCodeField = selectFormError('code');

const TwoFactorPanel = () => {
  const isLoading = useAppSelector(isProcessing);
  const error = useAppSelector(selectCodeField);
  const dispatch = useAppDispatch();
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    setFocus,
    clearErrors,
    setError,
    formState: { isValid, errors },
  } = useForm<DigitValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(twoFactorPanelSchema),
  });

  useEffect(() => {
    setFocus(DigitFields.digit1);
  }, [setFocus]);

  const onSubmit = async (values: DigitValues) => {
    const code = Object.values(DigitFields)
      .map((field) => values[field])
      .join('');

    dispatch(login({ codeTwoFactor: code }));
  };
  useEffect(() => {
    if (error?.message) {
      setError('digit1', error);
    }
  }, [error, setError]);
  return (
    <LoginWrapper>
      <LogoSubtitle label="Two-factor authentication" />
      <form
        className="flex h-full w-full flex-grow flex-col justify-between md:flex-grow-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputCode
          error={errors[DigitFields.digit1]}
          register={register}
          setValue={setValue}
          getValues={getValues}
          setFocus={setFocus}
        />
        <Button className='button-gradient-background-no-hover text-white' type="submit" disabled={isValid} isLoading={isLoading}>
          Verify code
        </Button>
      </form>
      <Button
        preset="secondary"
        onClick={() => {
          clearErrors();
          dispatch(clearFormError());
          dispatch(resetTwoFactor());
        }}
      >
        Cancel
      </Button>
      <p className="ml-3 pt-3 text-center text-sm text-black">
        {'Didn’t receive a code? '}
        <Href label="Resend code" />
      </p>
    </LoginWrapper>
  );
};

export default TwoFactorPanel;
