import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Dot from 'src/components/Dot';
import Loader from 'src/components/Loader';
import { isModuleLoaded, selectFromPath } from 'src/redux/appSelectors';
import { AppModule } from 'src/redux/appSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useAuth } from './AuthProvider';
import LoginPanel from './LoginPanel';
import TwoFactorPanel from './TwoFactorPanel';
import { is2FANeeded, selectCurrentFetched, selectCurrentUser } from './authSelectors';
import { fetchLoginMethods } from './authSlice';

export const Login = () => {
  const { session } = useAuth();
  const show2FA = useAppSelector(is2FANeeded);
  const from = useAppSelector(selectFromPath);
  const dispatch = useAppDispatch();
  const isLoginInitialized = useAppSelector((state) => isModuleLoaded(state, AppModule.AUTH));

  useEffect(() => {
    dispatch(fetchLoginMethods());
  }, [dispatch]);

  const currentUser = useAppSelector(selectCurrentUser);
  const isCurrentUserFetched = useAppSelector(selectCurrentFetched);

  if (session?.access_token && isCurrentUserFetched && currentUser?.organization_id === 1) {
    return <Navigate to={from || '/'} />;
  }
  if (!isLoginInitialized) {
    return (
      <div className="flex h-full min-h-screen w-full flex-col items-center justify-start bg-white md:justify-center md:bg-white">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className="flex h-full min-h-screen w-full flex-col items-center justify-start bg-white md:justify-center md:bg-white">
        {show2FA ? <TwoFactorPanel /> : <LoginPanel />}
        <div className="flex items-center justify-center pb-4 md:pt-4">
          <a
            className="text-xs text-black underline"
            href="https://livereach.ai/resources/security"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <Dot size={2} />
          <a
            className="text-xs text-black underline"
            href="https://livereach.ai/resources/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <Dot size={2} />
          <a
            className="text-xs text-black underline"
            href="https://livereach.ai/resources/contact"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </a>
        </div>
      </div>
    </>
  );
};

export default Login;
