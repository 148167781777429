import { Navigate, Route, Routes } from 'react-router-dom';
import { AppStateInit } from './AppStateInit';
import { useAuth } from './auth/AuthProvider';
import { useAppDispatch } from './store';
import { setFromPath } from './redux/appSlice';
import PricingForm from './pricingCalculator/PricingForm';

const AuthRoutes = () => {

  const auth = useAuth();
  const dispatch = useAppDispatch();

  if (!auth?.session?.access_token) {
    // eslint-disable-next-line no-restricted-globals
    dispatch(setFromPath(location.pathname + location.search));
    return <Navigate to={`/login`} replace />;
  }
  // if (!isCurrentUserFetched || currentUser?.organization_id !== 1) {
  //   return ;
  // }
  return (
    <AppStateInit>
      <Routes>
        <Route path="*" element={<PricingForm />} />
        <Route path="/" element={<PricingForm />} />
        {/* <Route path="/" element={<Navigate to="/live" replace />} /> */}
      </Routes>
    </AppStateInit>
  )
};

export default AuthRoutes;