import Logo from '../Logo';

type LogoSubtitleProps = {
  label?: string;
};

const LogoSubtitle = ({ label }: LogoSubtitleProps) => (
  <>
    <div className="m-auto my-10 select-none">
      <Logo color='#000'/>
    </div>
    {!!label && <p className="mb-6 text-center text-sm text-grey-200">{label}</p>}
  </>
);

export default LogoSubtitle;
