// import configDev from '../config/dev';
import axiosInstance from '../services/axios';
import { Session } from './token';

const loginWithCode = async (code: string, signal: AbortSignal): Promise<Session> => {
  const grant_type = 'authorization_code';
  const realm = 'app.livereach.ai';
  const { data } = await axiosInstance.post(
    '/login',
    {
      grant_type,
      realm,
      code,
    },
    {
      signal,
    },
  );

  return data;
};

export default loginWithCode;
