import { TaskAbortError } from '@reduxjs/toolkit';
import { CanceledError } from 'axios';
import { toast } from 'react-toastify';
import { AppModule, initializeAppModule } from 'src/redux/appSlice';
import { RootState } from 'src/store';
import { AppStartListening } from './../listenerMiddleware';
import axiosInstance from '../services/axios';
import { selectTwoFactorAccessToken, selectTwoFactorUserAttemptData } from './authSelectors';
import {
  LoginActionPayload,
  Session,
  SessionState,
  clearFormError,
  currentUserFetchError,
  currentUserFetched,
  currentUserFetching,
  error,
  errorForm,
  fetchCurrentUser,
  fetchLoginMethods,
  login,
  persistSession,
  resetTwoFactor,
  setIsProcessing,
  setSSOMethods,
  setSession,
  setTwoFactorAttemptData,
  setTwoFactorConfirmationData,
} from './authSlice';
import getLoginMethods from './loginMethods';
import loginWith2FA from './loginWith2FA';
import loginWithCode from './loginWithCode';
import loginWithPassword from './loginWithPassword';
import { User } from './types';
import { ApiResponse, getAuthorizationHeaders } from 'src/services/types';

type Mode = 'username' | 'twoFactor' | 'sso';

type GetLoginPromiseReturn = {
  request: Promise<Session>;
  persist: boolean;
  mode: 'twoFactor' | 'sso' | 'username';
};

function getLoginPromise(
  action: {
    payload: LoginActionPayload;
    type: string;
  },
  state: RootState,
  signal: AbortSignal,
): GetLoginPromiseReturn {
  if ('codeSSO' in action.payload) {
    return {
      persist: true,
      request: loginWithCode(action.payload.codeSSO, signal),
      mode: 'sso',
    };
  } else if ('codeTwoFactor' in action.payload) {
    return {
      persist: !!selectTwoFactorUserAttemptData(state)?.isRememberMe,
      request: loginWith2FA(
        action.payload.codeTwoFactor,
        selectTwoFactorAccessToken(state) || '',
        signal,
      ),
      mode: 'twoFactor',
    };
  }
  return {
    persist: action.payload.isRememberMe,
    request: loginWithPassword(action.payload.username, action.payload.password, signal),
    mode: 'username',
  };
}

const error401Messages: Record<Mode, (e: any) => string> = {
  sso: (err: any) => err?.response?.data?.result?.errorMessage || 'Unknown error.',
  twoFactor: () => 'Wrong code',
  username: (err: any) => err?.response?.data?.result?.errorMessage || 'Invalid credentials.',
};

const addAuthListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: resetTwoFactor,
    effect: () => {
      toast.dismiss();
    },
  });

  startListening({
    actionCreator: login,
    effect: async (action, api) => {
      api.cancelActiveListeners();
      api.dispatch(clearFormError());
      api.dispatch(setIsProcessing(true));

      const {
        request: sessionRequest,
        persist,
        mode,
      } = getLoginPromise(action, api.getState(), api.signal);

      try {
        const sessionResponse: Session = await sessionRequest;
        const sessionStateData: SessionState = {
          access_token: sessionResponse.access_token,
          expires_at: Date.now() + 1000 * sessionResponse.expires_in,
          refresh_token: sessionResponse.refresh_token,
          token_type: sessionResponse.token_type,
        };
        api.dispatch(setSession(sessionStateData));
        api.dispatch(fetchCurrentUser());
        api.dispatch(persistSession(persist));
      } catch (err: any) {
        if (err instanceof CanceledError) {
          return;
        }
        if (err?.response?.status === 402) {
          if ('username' in action.payload) {
            api.dispatch(setTwoFactorAttemptData(action.payload));
          }
          const { access_token, expires_in } = err?.response?.data?.content || {};
          const expires_at = Date.now() + 1000 * parseInt(expires_in);
          api.dispatch(setTwoFactorConfirmationData({ access_token, expires_at }));
        } else if (err?.response?.status === 401) {
          if (err?.response?.data?.form) {
            const { form } = err.response.data;
            const fields = Object.keys(form);
            fields.forEach((field) => api.dispatch(errorForm({ field, message: form[field] })));
            if (fields.includes('_')) {
              api.dispatch(error(form['_']));
            }
          } else {
            api.dispatch(error(error401Messages[mode](err)));
          }
        } else {
          api.dispatch(error('Unknown error'));
        }
      } finally {
        api.dispatch(setIsProcessing(false));
      }
    },
  });

  startListening({
    actionCreator: error,
    effect: ({ payload }) => {
      toast.error(payload);
    },
  });

  startListening({
    actionCreator: fetchCurrentUser,
    effect: async (_action, api) => {
      api.unsubscribe();
      api.dispatch(currentUserFetching());
      try {
        const response = await axiosInstance.get<ApiResponse<User>>('/users/me', {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });
        api.dispatch(currentUserFetched(response.data.content));
      } catch (e: any) {
        api.dispatch(currentUserFetchError(e.toString()));
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: fetchLoginMethods,
    effect: async (_action, api) => {
      api.unsubscribe();
      try {
        const maybeMethods = await Promise.race([getLoginMethods(api.signal), api.delay(5000)]);
        if (maybeMethods) {
          api.dispatch(setSSOMethods(maybeMethods));
        }
        api.dispatch(initializeAppModule(AppModule.AUTH));
      } catch (error) {
        if (error instanceof CanceledError || error instanceof TaskAbortError) {
          return;
        }
        api.dispatch(initializeAppModule(AppModule.AUTH));
      } finally {
        api.subscribe();
      }
    },
  });
};

export default addAuthListeners;
