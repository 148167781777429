import { ReactNode } from 'react';

type Props = {
  label?: string | ReactNode;
  name?: string;
};

const Label = ({ label, name }: Props) =>
  label ? (
    <label className="mb-0 h-fit block text-sm text-black" htmlFor={name}>
      {label}
    </label>
  ) : null;

export default Label;
