import { Fragment } from 'react';
import {
  FieldError,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';
import { DigitFields, DigitValues } from 'src/auth/types';
import Error from './Error';

type InputCodeProps = {
  error?: FieldError;
  register: UseFormRegister<DigitValues>;
  setValue: UseFormSetValue<DigitValues>;
  setFocus: UseFormSetFocus<DigitValues>;
  getValues: UseFormGetValues<DigitValues>;
};

type InputNumberElementProps = {
  fieldName: DigitFields;
  nextFieldName?: DigitFields;
  previousFieldName?: DigitFields;
};

const digitFields = Object.values(DigitFields);

const InputNumberElement = ({
  register,
  setValue,
  fieldName,
  setFocus,
  nextFieldName,
  previousFieldName,
  error,
}: InputNumberElementProps & InputCodeProps) => {
  const handleChange = (value: string) => {
    return value.toString().slice(-1);
  };

  return (
    <label className="relative mr-1.5 flex-grow last:mr-0">
      <input
        {...register(fieldName)}
        onChange={({ target: { value } }) => {
          setValue(fieldName, handleChange(value));
          if (nextFieldName && fieldName) setFocus(nextFieldName);
          if (previousFieldName && value === '') setFocus(previousFieldName);
        }}
        type="number"
        min="0"
        max="9"
        placeholder="_"
        className={`h-16 w-full rounded-md  border
                    border-lraiorange bg-white text-center
                    text-sm text-black caret-transparent 
                    focus-visible:border-lraiorange md:h-12 
                    ${error && 'border-red-600'}`}
      />
    </label>
  );
};

const InputCode = ({ setValue, error, register, setFocus, getValues }: InputCodeProps) => {
  return (
    <div
      className="flex w-full flex-col"
      onPaste={({ clipboardData }) => {
        const code = clipboardData.getData('text');
        digitFields.forEach((field, index) => {
          setValue(field, code[index]);
        });
      }}
    >
      <span className="mb-2 text-sm text-black">6-digit code</span>
      <div className="pb-6">
        <div className="relative flex justify-between ">
          {digitFields.map((fieldName, index, arr) => {
            return (
              <Fragment key={fieldName}>
                <InputNumberElement
                  error={error}
                  fieldName={fieldName}
                  nextFieldName={arr[index + 1]}
                  previousFieldName={arr[index - 1]}
                  setValue={setValue}
                  setFocus={setFocus}
                  getValues={getValues}
                  register={register}
                />
              </Fragment>
            );
          })}
        </div>
        <Error {...error} />
      </div>
    </div>
  );
};

export default InputCode;
