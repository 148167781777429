import SvgLogo265 from "src/Logo";
// import useWindowScrollPositions from "src/hooks/useScrollPosition";
import clsx from "clsx";
import { useState } from "react";
import SvgCheck12 from "src/icons/Check12";

const PricingFormHeader = () => {
  // const scrollPosition = useWindowScrollPositions();
  return (
    <div className={clsx("whitespace-nowrap flex justify-between xl:flex-row w-full nav-bar z-dropdown xl:px-[9%] 2xl:px-[18%] h-[90px] fixed bg-lraidark border-b-8 border-lraiorange")}>
      <div className="w-[148px] cursor-pointer self-center">
        {/* <Link ref="/" to={"https://wwww.livereach.ai"}> */}
          <SvgLogo265 color="#fff"/>
        {/* </Link> */}
      </div>
    </div>
  );
}

const PricingForm = () => {
  const [cameras, setCameras] = useState<number>(12);
  const [duration, setDuration] = useState<number>(0);
  const [warranty, setWarranty] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [monthlyPrice, setMonthlyPrice] = useState<number>(0);
  const [showPrice, setShowPrice] = useState<boolean>(false);
  const [licenses, setLicenses] = useState<number>(0);
  const [storage, setStorage] = useState<number>(0);
  const [term, setTerm] = useState<number>(0);
  const [industry, setIndustry] = useState<string>();

  const calculatePrice = () => {
    const softwareMonthlyLicense = 10;
    const fixedInterestRate = 0.15; 
    let hardwareCost = 0;
    // let hardwareType = "";
    if (cameras >= 1 && cameras <= 32) {
      hardwareCost = 4500;
      // hardwareType = "Standard Server";
    } else if (cameras >= 33 && cameras <= 64) {
        hardwareCost = 6500;
        // hardwareType = "Large Server";
    } else if (cameras >= 65 && cameras <= 80) {
        hardwareCost = 10500;
        // hardwareType = "Enterprise 2U Server";
    } else if (cameras > 80) {
        const bundles = Math.ceil(cameras / 80);
        hardwareCost = bundles * 10500; // Assuming each bundle costs $10,500
        // hardwareType = `${bundles}x Enterprise 2U Server Bundles`;
    }
    if (duration === 60) {
      hardwareCost += 3000; // Add $3,000 for 60 days
    } else if (duration === 90) {
        hardwareCost += 6000; // Add $6,000 for 90 days (cumulative)
    } else if (duration === 120) {
        hardwareCost += 9000; // Add $9,000 for 120 days
    } else if (duration === 150) {
        hardwareCost += 12000; // Add $12,000 for 150 days
    } else if (duration === 180) {
        hardwareCost += 15000; // Add $15,000 for 180 days
    }

    const softwareCost5Years = softwareMonthlyLicense * cameras * warranty;
    let totalPrice = softwareCost5Years + hardwareCost;
    if (warranty === 120) { // 10 Year License
      totalPrice = (softwareCost5Years + hardwareCost) * 1.75; 
    }

    let discount = 0;

    switch (industry) {
        case 'carwash':
        // eslint-disable-next-line no-fallthrough
        case 'nonprofit':
            discount = 0.4; // 35% discount
            break;
        case 'education':
            discount = 0.10; // 10% discount
            break;
        case 'property':
            discount = 0.05; // 5% discount
            break;
        default :
          discount = 0;
          break;
    }

    totalPrice *= (1 - discount); 
    
    const adjustedTotal = totalPrice * (1 + fixedInterestRate);
    const monthlyPayment = adjustedTotal / warranty;

    setLicenses(cameras);
    setTerm(warranty);
    setStorage(duration);
    setMonthlyPrice(monthlyPayment);
    setPrice(adjustedTotal);
    setShowPrice(true);
  };

  return (
    <div className="w-full xl:w-screen h-full bg-white flex xl:overflow-x-hidden">
      <PricingFormHeader />
      <div className="py-10 xl:px-[9%] 2xl:px-[18%] mt-[90px] flex flex-col xl:flex-row h-full w-full justify-between gap-8 xl:gap-0">
        <div className="flex flex-col w-full xl:w-[500px]">
          <div className="text-3xl xl:text-4xl text-black font-bold">
            Build the camera system <br/> for your needs
          </div>
          <div className="flex flex-col py-6 border-b border-grey-200">
            <div className='flex flex-col xl:flex-col'>
                <label
                  htmlFor="cameras"
                  className='text-base xl:text-lg text-lraidark w-full mb-2'
                >
                  <span className='h-fit self-center'>
                    Number of Camera Streams
                  </span>
                </label>
                <div className='flex flex-col w-full'>
                  <input
                    type="number"
                    id="cameras"
                    placeholder='Input the number of camera streams'
                    className={clsx('bg-white border-2 text-base text-lraiorange flex items-center px-2 py-1 rounded-md focus:outline-none focus-visible:ring-0 border-lraiorange')}
                    value={cameras}
                    onChange={(e) => setCameras(parseInt(e.target.value))}
                  />
                  {/* <div className="text-right text-lraiorange"> cameras</span> */}
                </div>
              </div>
          </div>
          <div className="flex flex-col py-6 border-b border-grey-200">
            <div className='flex flex-col xl:flex-col'>
                <label
                  htmlFor="storage"
                  className='text-base xl:text-lg text-lraidark w-full mb-2'
                >
                  <span className='h-fit self-center'>
                    Select storage duration (in days)
                  </span>
                </label>
                <div className='flex flex-row w-full gap-2'>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", duration === 30 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> duration !== 30 && setDuration(30)}>
                    30&nbsp;D&nbsp;
                    {duration === 30 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", duration === 60 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> duration !== 60 && setDuration(60)}>
                    60&nbsp;D&nbsp;
                    {duration === 60 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", duration === 90 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> duration !== 90 && setDuration(90)}>
                    90&nbsp;D&nbsp;
                    {duration === 90 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", duration === 120 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> duration !== 120 && setDuration(120)}>
                    120&nbsp;D&nbsp;
                    {duration === 120 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", duration === 150 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> duration !== 150 && setDuration(150)}>
                    150&nbsp;D&nbsp;
                    {duration === 150 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", duration === 180 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> duration !== 180 && setDuration(180)}>
                    180&nbsp;D&nbsp;
                    {duration === 180 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                </div>
              </div>
          </div>
          <div className="flex flex-col py-6 border-b border-grey-200">
            <div className='flex flex-col xl:flex-col'>
                <label
                  htmlFor="warranty"
                  className='text-base xl:text-lg text-lraidark w-full mb-2'
                >
                  <span className='h-fit self-center'>
                    License & Warranty Term (in months)
                  </span>
                </label>
                <div className='flex flex-row w-full justify-between'>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", warranty === 24 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> warranty !== 24 && setWarranty(24)}>
                    24&nbsp;M&nbsp;
                    {warranty === 24 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", warranty === 36 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> warranty !== 36 && setWarranty(36)}>
                    36&nbsp;M&nbsp;
                    {warranty === 36 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", warranty === 60 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> warranty !== 60 && setWarranty(60)}>
                    60&nbsp;M&nbsp;
                    {warranty === 60 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                  <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-24 text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", warranty === 120 ? "bg-lraiorangeselected": "bg-white")} onClick={()=> warranty !== 120 && setWarranty(120)}>
                    120&nbsp;M&nbsp;
                    {warranty === 120 ? <SvgCheck12 color="lraiorange"/> : <></>}
                  </div>
                </div>
              </div>
          </div>
          <div className="flex flex-col py-6 border-b border-grey-200">
            <div className='flex flex-col xl:flex-col'>
                <label
                  htmlFor="industry"
                  className='text-base xl:text-lg text-lraidark w-full mb-2'
                >
                  <span className='h-fit self-center'>
                    Select the industry
                  </span>
                </label>
                <div className="flex flex-col gap-4 w-full">
                  <div className='flex flex-row w-full gap-8'>
                    <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-fit text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", industry === 'carwash' ? "bg-lraiorangeselected": "bg-white")} onClick={()=> industry !== 'carwash' && setIndustry('carwash')}>
                      Car&nbsp;Wash&nbsp;
                      {industry === 'carwash' ? <SvgCheck12 color="lraiorange"/> : <></>}
                    </div>
                    <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-fit text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", industry === 'property' ? "bg-lraiorangeselected": "bg-white")} onClick={()=> industry !== 'property' && setIndustry('property')}>
                      Property&nbsp;Management&nbsp;
                      {industry === 'property' ? <SvgCheck12 color="lraiorange"/> : <></>}
                    </div>
                    <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-fit text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", industry === 'education' ? "bg-lraiorangeselected": "bg-white")} onClick={()=> industry !== 'education' && setIndustry('education')}>
                      Education&nbsp;
                      {industry === 'education' ? <SvgCheck12 color="lraiorange"/> : <></>}
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-8">
                    <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-fit text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", industry === 'nonprofit' ? "bg-lraiorangeselected": "bg-white")} onClick={()=> industry !== 'nonprofit' && setIndustry('nonprofit')}>
                        Non&nbsp;Profit&nbsp;
                        {industry === 'nonprofit' ? <SvgCheck12 color="lraiorange"/> : <></>}
                    </div>
                    <div className={clsx("border border-lraiorange text-lraiorange rounded-lg px-3 cursor-pointer py-3 h-12 w-fit text-sm xl:text-base font-semibold text-center items-center flex flex-row justify-center", industry === 'other' ? "bg-lraiorangeselected": "bg-white")} onClick={()=> industry !== 'other' && setIndustry('other')}>
                      Other&nbsp;
                      {industry === 'other' ? <SvgCheck12 color="lraiorange"/> : <></>}
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <button type="submit" className="mt-6 button-gradient-background-no-hover h-[60px] content-center text-center text-white w-full rounded-md" onClick={calculatePrice} disabled={warranty === 0 || duration === 0 || cameras <= 0}>
            Check Price
          </button>
        </div>
        <div className="flex w-full xl:w-1/2"> 
          { showPrice && (
            <div className="gradient-orange-background h-[662px] w-full xl:w-[600px] rounded-2xl border border-white flex flex-col px-5 p-10 justify-between">
              <div className="flex flex-col gap-3 text-center mt-10">
              <div className="flex flex-col text-white text-3xl xl:text-4xl font-semibold">
                {licenses} Camera System with
              </div>
              <div className="flex flex-col text-white text-3xl xl:text-4xl font-semibold">
                {storage} Day Storage Retention
              </div>
              </div>
              <div className="border border-white rounded-lg flex flex-col gap-6 p-6 text-center">
                <div className="text-white text-lg xl:text-2xl">
                  {term} month License & Warranty Term at
                </div>
                <div className="flex flex-col gap-6">
                  <div className="text-white text-3xl xl:text-5xl font-bold">
                    ${Intl.NumberFormat().format(Number(price.toFixed(2)))}
                  </div>
                  <div className="text-white text-lg xl:text-2xl">
                    or
                  </div>
                  <div className="text-white text-3xl xl:text-5xl font-bold">
                    ${Intl.NumberFormat().format(Number(monthlyPrice.toFixed(2)))}/month
                  </div>
                </div>
                <div className="text-white text-base xl:text-lg">
                (excludes interest fees if financing is selected)
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PricingForm;

