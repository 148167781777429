export enum LoginFields {
  username = 'username',
  password = 'password',
  isRememberMe = 'isRememberMe',
}

export enum DigitFields {
  digit1 = 'digit1',
  digit2 = 'digit2',
  digit3 = 'digit3',
  digit4 = 'digit4',
  digit5 = 'digit5',
  digit6 = 'digit6',
}

export type LoginValues = {
  [LoginFields.isRememberMe]: boolean;
  [LoginFields.username]: string;
  [LoginFields.password]: string;
};

export type DigitValues = {
  [DigitFields.digit1]: string;
  [DigitFields.digit2]: string;
  [DigitFields.digit3]: string;
  [DigitFields.digit4]: string;
  [DigitFields.digit5]: string;
  [DigitFields.digit6]: string;
};

export type TwoFA = {
  factor: string;
  contact: string;
  verified: boolean;
};

export type User = {
  ad_user: boolean;
  created: Date;
  disabled: boolean;
  email: string;
  email_verified: boolean;
  eula_agreed: boolean;
  id: number;
  language: string;
  name: string;
  organization_id: number;
  phone_number: string;
  requires_email_verification: boolean;
  role: null | object;
  location_ids?: number[];
  updated: Date;
  avatar: string | null;
  organization_name?: string;
  cloud_viewer: boolean;
  '2fa'?: TwoFA;
};